.container {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  color: var(--color-text-bright-tertiary);
  font-size: 14px;
  font-weight: 400;
}

.label {
  font-size: 12px;
}

.invalid {
  border: 1px solid var(--color-alert-quinary);
}

.error {
  color: var(--color-alert-quinary);
  font-size: 12px;
  margin: 0;
}
