.userInfo {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: auto;

  &Name {
    color: var(--color-white);
    font-size: 14px;
  }
}
