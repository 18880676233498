.header {
  &Container {
    padding: 12px 24px;
    border-bottom: 1px solid var(--color-bright-secondary);
    display: grid;
    align-items: center;
    grid-template-columns: minmax(200px, auto) 1fr auto;
    column-gap: 12px;
    height: 64px;
  }

  &Right {
    display: flex;
    align-items: center;
    margin-left: auto;
    column-gap: 12px;
  }
}

.actions {
  background-color: var(--color-main-quinary);
  border-radius: var(--border-radius-sm);
  column-gap: 12px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
}

.createLink {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  column-gap: 4px;
  background-color: var(--color-main-default);
  color: var(--color-white);
  border-radius: 12px;
}
