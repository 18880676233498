.container {
  display: grid;
  grid-template-rows: auto 48px;
  height: 100%;
}

.table {
  border-bottom: 1px solid var(--color-bright-secondary);
}

.toggleBtn {
  background-color: transparent;
  cursor: pointer;
  outline: none;
}
