.button {
  cursor: pointer;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 10px 16px;
  border: none;
  display: flex;
  position: relative;
  background-color: transparent;
  color: var(--color-text-bright-tertiary);
  align-items: center;

  column-gap: 4px;
  transition: opacity 0.1s ease-in-out;
  min-height: 40px;

  &--align-left {
    justify-content: start;
  }

  &--align-right {
    justify-content: end;
  }

  &--align-center {
    justify-content: center;
  }

  &--default {
    background-color: var(--color-main-default);
    color: var(--color-white);
  }

  &--secondary {
    background-color: var(--color-bright-secondary);
    color: var(--color-text-bright-secondary);
  }

  &--stroke {
    border: 1px solid var(--color-bright-tertiary);
  }

  &--stroke-danger {
    border: 1px solid var(--color-bright-tertiary);
    color: var(--color-alert-quinary);
  }

  &--empty {
    background-color: transparent;
    min-height: auto;
  }

  &--transparent {
    background-color: transparent;
  }

  &--empty-primary {
    background-color: transparent;
    color: var(--color-main-default);
    min-height: auto;
    padding: 0;
  }

  &--is-loading {
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    opacity: 0.9;
  }

  &[disabled] {
    background-color: var(--color-bright-secondary);
    color: var(--color-text-dark-quaternary);
  }

  &--large {
    height: 48px;
  }

  &--full-width {
    width: 100%;
  }

  &Spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
