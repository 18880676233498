.modal {
  border-radius: 16px;
  box-shadow: 0px -6px 48px 0px rgba(9, 13, 17, 0.08);

  &Header {
    border-bottom: 1px solid var(--color-bright-secondary);
    padding: 12px 24px;
  }

  &Header h2 {
    line-height: 32px;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  &Header h2,
  &Header h3 {
    color: var(--color-text-bright-primary);
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
  }

  &Footer {
    border-top: 1px solid var(--color-bright-secondary);
    padding: 12px 24px;
  }

  &Body {
    padding: 16px 24px;
  }
}

.closeBtn {
  color: var(--color-text-bright-quaternary);
  margin-left: auto;
}
