.container {
  display: grid;
  grid-template-rows: 64px 1fr 48px;
  height: 100%;
}

.modal {
  width: 432px;

  &Title {
    color: var(--color-text-bright-secondary);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }

  &Header {
    border-bottom: none;
    padding-top: 32px;
    padding-bottom: 0;
  }

  &Footer {
    border-top: none;
    padding-top: 0;
    padding-bottom: 32px;
  }

  &Footer button {
    flex-grow: 1;
  }
}
