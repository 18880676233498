.wrapper {
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  width: 320px;
  row-gap: 16px;
  margin-bottom: 16px;

  &Title {
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
    margin: 0 0 16px;
    text-align: center;
  }
}

.authLink {
  text-align: center;
}

.error {
  color: var(--color-alert-quinary);
  text-align: center;
}
