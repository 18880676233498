.flex {
  display: flex;
}

.column-gap-zero {
  column-gap: 0;
}

.column-gap-md {
  column-gap: 16px;
}

.column-gap-sm {
  column-gap: 12px;
}

.column-gap-xs {
  column-gap: 8px;
}
