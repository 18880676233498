.modal {
  width: 626px;

  &Footer {
    margin-top: 64px;
  }
}

.form {
}
