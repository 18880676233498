.button {
  --size-sm: 32px;
  --size-md: 40px;

  background-color: transparent;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  padding: 0;
  color: inherit;
  display: grid;
  place-items: center;

  &--stroke {
    border: 1px solid var(--color-bright-tertiary);
  }

  &--secondary {
    background-color: var(--color-bright-secondary);
  }

  &--size-sm {
    min-width: var(--size-sm);
    min-height: var(--size-sm);
  }

  &--size-md {
    min-width: var(--size-md);
    min-height: var(--size-md);
  }
}
