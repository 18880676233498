.appLayout {
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr;
  height: 100%;
}

.unauthorizedLayout {
  display: grid;
  grid-template-rows: 64px 1fr;
  row-gap: 48px;
}

.content {
  height: 100%;
}

.header {
  border-bottom: 1px solid var(--color-bright-secondary);
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
