@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@import "normalize";
@import "vars";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

a {
  text-decoration: none;
}

.container {
  &--center {
    margin: 24px 173px;
  }
}

.ReactModalPortal {
  z-index: 9999;
  position: relative;
}
.ReactModal__Overlay {
  background-color: transparent !important;
}
.ReactModal__Body--open {
  overflow-y: hidden;
}
