@import "mixins";

.label {
  color: var(--color-text-bright-tertiary);
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
  line-height: 16px;
}

.input {
  @include input-base;

  &Small {
    height: 40px;
  }

  &::placeholder {
    color: var(--color-text-bright-tertiary);
  }

  &Invalid {
    border-color: var(--color-alert-quinary);
  }

  &:disabled {
    opacity: 0.7;
  }
}

.error {
  color: var(--color-alert-quinary);
  font-size: 12px;
  margin: 0;
}
