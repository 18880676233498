@import "@/components/UI/Input/mixins";

.container {
  color: var(--color-text-bright-tertiary);
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
  line-height: 16px;
}

.input {
  @include input-base;
}

.invalid {
  border-color: var(--color-alert-quinary);
}

.error {
  color: var(--color-alert-quinary);
  font-size: 12px;
  margin: 0;
}
