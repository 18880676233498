@mixin input-base {
  background-color: var(--color-bright-secondary);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--color-bright-secondary);
  color: var(--color-text-bright-tertiary);
  padding: 12px 16px;
  height: 48px;
  width: inherit;
  outline: none;
  font-size: 14px;
  line-height: 20px;
  transition: border-color 0.1s ease-in-out;
}
