.wrapper {
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  width: 320px;
  row-gap: 16px;
  margin-bottom: 16px;

  &Title {
    font-size: 28px;
    line-height: 40px;
    font-weight: 500;
    margin: 0;
  }
}

.registerLink {
  text-align: center;
}

.error {
  color: var(--color-alert-quinary);
  text-align: center;
}
