.pagination {
  padding: 14px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &Col {
    display: flex;
    align-items: center;
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-bright-secondary);
    column-gap: 8px;
  }

  &Grey {
    color: var(--color-text-bright-quaternary);
    margin-right: 24px;
  }

  &Select {
  }

  &Actions {
    display: flex;
    align-items: center;
  }
}
