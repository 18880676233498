.nav {
  margin-bottom: 16px;

  &List {
    list-style-type: none;
    color: var(--color-text-dark-secondary);
    margin: 0;
    padding: 0;
  }

  &ListSubmenu {
    margin-left: 12px;
  }

  &SubLinkActive::before {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--color-white);
    top: 50%;
    position: absolute;
    left: 12px;
    transform: translateY(-50%);
  }

  &Item {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }

  &Link {
    border-radius: 4px;
    color: inherit;
    transition: background-color 0.2s ease-in-out;
    display: flex;
    line-height: 20px;
    justify-content: center;
    font-weight: 400;
    flex-direction: column;
    position: relative;
    padding: 10px 12px;
  }

  &SubLinkActive {
    padding-left: 24px;
  }

  &Link:hover,
  &Row:hover,
  &LinkActive,
  &RowOpened {
    background-color: var(--color-dark-secondary);
    color: var(--color-white);
  }

  &Row {
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &RowCol {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  &ToggleButton {
    color: var(--color-text-dark-secondary);
    column-gap: 8px;
    padding: 10px 8px 10px 12px;
  }

  &ToggleButton svg:last-child {
    margin-left: auto;
  }
}
